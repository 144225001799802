<template>
  <div class="container">
    <div class="row">
      <div class="col s12 m6 l6 center">
        <img
          src="~@/assets/img/logo.png"
          alt="image"
          class="responsive-img"
          style="width: 90%"
        />
      </div>
      <div class="col s12 m6 l6" style="margin-top: 5%">
        <h4 style="color: #f7931e; text-align: center">Fikrini Yazabilirsin</h4>
        <div class="row">
          <div class="row">
            <div class="input-field col s10">
              <i class="material-icons prefix" style="color: white">supervisor_account</i>
              <input
                type="text"
                v-model="adsoyad"
                placeholder="Adınız Soyadınız"
                style="color: white"
                required="required"
                autocomplete="off"
              />
            </div>
            <div class="row">
              <div class="input-field col s10">
                <i class="material-icons prefix" style="color: white">mobile_friendly</i>
                <input
                  type="text"
                  v-mask="'0 (5##) ###-####'"
                  v-model="telefon"
                  placeholder="Telefon Numaranız"
                  style="color: white"
                  required="required"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="row">
              <div class="input-field col s10">
                <i class="material-icons prefix" style="color: white">textsms</i>
                <textarea
                  rows="8"
                  placeholder="Fikrinizi yazabilirsiniz..."
                  class="materialize-textarea"
                  required="required"
                  autocomplete="off"
                  v-model="fikir"
                  style="color: white"
                />
                <p class="kucukyazi">
                  Kalan Karakter: <b>{{ kalan }}</b>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="row col s10">
                <div class="right">
                  <vue-recaptcha
                    ref="recaptcha"
                    @verify="onVerify"
                    @expired="onExpired"
                    sitekey="6LdtnSQpAAAAANwPG6f2se7fIoh-Yn9GSxb85W1W"
                  ></vue-recaptcha>
                </div>
              </div>
            </div>
            <div class="row col s10">
              <div class="right">
                <button
                  class="btn waves-effect waves-light"
                  type="button"
                  @click="Kaydet"
                  :disabled="processing"
                >
                  FİKRİNİ PAYLAŞ
                  <i class="material-icons right">send</i>
                </button>
              </div>
            </div>
          </div>
          <h6 style="color: white">
            Gönderilerinize resimde eklemek isterseniz <br />
            uygulama marketlerden BenOlsamAks Uygulamasını indirin
          </h6>
          <br />
          <div class="col s12 m6 l6 center">
            <a
              href="https://play.google.com/store/apps/details?id=com.aksarayvaliligi.benolsam&pcampaignid=web_share"
              target="_blank"
            >
              <img
                src="~@/assets/img/play.png"
                alt="image"
                class="responsive-img"
                style="width: 70%"
              />
            </a>
          </div>
          <div class="col s12 m6 l6 center">
            <a
              href="https://apps.apple.com/us/app/benolsamaks/id1639008032"
              target="_blank"
            >
              <img
                src="~@/assets/img/appstore.png"
                alt="image"
                class="responsive-img"
                style="width: 70%"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoruslerService from "../services/gorusler";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  components: { VueRecaptcha },

  name: "HelloWorld",
  data() {
    return {
      adsoyad: "",
      telefon: "",
      fikir: "",
      btnenabled: false,
      processing: false,
      ipAdress: "",
      backend: "",
      verifyrobot: false,
    };
  },
  computed: {
    kalan() {
      return 1000 - this.fikir.length;
    },
  },
  methods: {
    onVerify() {
      this.verifyrobot = true;
    },
    onExpired() {
      this.verifyrobot = false;
    },
    getIpAdress() {
      GoruslerService.getIpAdress().then((response) => {
        if (response.status == 200) {
          this.ipAdress = response.data.ip;
        }
      });
    },
    async Kaydet() {
      if (this.verifyrobot == false) {
        this.$vToastify.error("Lütfen robot olmadığınızı doğrulayınız.", "Hata");
        return;
      } else {
        this.btnenabled = false;
        this.processing = true;

        let data = {
          adsoyad: this.adsoyad,
          telefon: this.telefon,
          fikir: this.fikir,
          ipAdres: this.ipAdress,
        };
        if (this.adsoyad == "" || this.telefon == "" || this.fikir == "") {
          this.$vToastify.error("Lütfen tüm alanları doldurunuz.", "Hata");
          setTimeout(() => {
            this.processing = false;
          }, 8000);
        } else {
          GoruslerService.gorusEkle(data).then((res) => {
            if (res.data.status == "success") {
              setTimeout(() => {
                this.processing = false;
                this.adsoyad = "";
                this.telefon = "";
                this.fikir = "";
                this.onExpired();
              }, 8000);
              this.$vToastify.success("Fikirleriniz başarıyla gönderildi", "Başarılı");
            } else {
              this.$vToastify.error("Veri Gönderilirken hata oluştu.", "Hata");
              setTimeout(() => {
                this.processing = false;
              }, 8000);
            }
          });
        }
      }
    },
  },
  mounted() {
    this.getIpAdress();
  },
};
</script>

<style>
body {
  background-image: url("~@/assets/img/111.jpg");
}
.kucukyazi {
  color: white;
  font-size: 9px;
  text-align: right;
}
.input-field label {
  color: #fff;
}
.input-field input[type="text"]:focus + label {
  color: #fff;
}
/* label underline focus color */
.input-field input[type="text"]:focus {
  border-bottom: 1px solid #fff;
  box-shadow: 0 1px 0 0 #fff;
}
/* valid color */
.input-field input[type="text"].valid {
  border-bottom: 1px solid #fff;
  box-shadow: 0 1px 0 0 #fff;
}
/* invalid color */
.input-field input[type="text"].invalid {
  border-bottom: 1px solid #fff;
  box-shadow: 0 1px 0 0 #fff;
}
/* icon prefix focus color */
.input-field .prefix.active {
  color: #fff;
}
.input-field {
  color: orange;
}
</style>
