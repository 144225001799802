import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)
import VueToastify from "vue-toastify";
Vue.use(VueToastify, {
  position : "top-right",
  successDuration : 8000,
  errorDuration : 8000,
  theme : "light",
});
new Vue({
  render: h => h(App),
}).$mount('#app')
