import { api_base_url } from '../contstant/config';
import axios from 'axios'

class GoruslerService {
    gorusEkle(gidenVeri){
        return axios.post(api_base_url + 'gorusler/ekle' , gidenVeri);
    }
    getIpAdress(){
        return axios.get('https://api.ipify.org?format=json');
    }
    
} 
export default new GoruslerService();
